import React, {Fragment, useEffect, useState} from 'react'
import LinkTiles from './header-link-tiles'
import HeaderContactDetails from './header-contact-details'
import HeaderHamburgerMenu from './header-hamburger-menu'
import { NavLink } from 'react-router-dom'
import { metadata } from '../../../app-config'
import config from '../../../config.json'
import {getAllFeatureArticles} from '../../../content/feature-articles/action'
import axios from 'axios'
import StoreManager from '../../../redux/store-manager'
import reducers from '../../../redux/reducers'

import { connect } from 'react-redux'
StoreManager.registerReducers(reducers)

require ('./header.scss')

const Header = props =>{
	const {headerLinks, getAllFeatureArticles} = props

	const [darkMode, setDarkMode] = useState(false)
	//adding headerLinksState to refresh highlighted link clicked
	const [headerLinksState, setHeaderLinksState] = useState(headerLinks)

	//start up loading
	useEffect(()=>{
		 getAllFeatureArticles()

		const payload = {
			"width":window.innerWidth || document.body.clientWidth,
			"height":window.innerHeight || document.body.clientHeight
		}

		axios.post('/api/BI/',payload)
			.then(
				response => {
					// console.log(response.data)
				}
			)
	},[])

	//dark mode toggling
	useEffect(()=>{
		if(!darkMode)return
		const element = document.getElementById("rfgdz")
		element.classList.toggle("dark-mode")

	},[darkMode])




	return (
		<Fragment>
			<div className="header">
				<HeaderContactDetails
					primaryMobile={true}
					email={true}
				/>
				<HeaderHamburgerMenu
					title={config.WEBSITE_NAME}
					links={headerLinks}
					image={config.HEADER_IMAGE}
				/>
				<div className="headerContent">
					<div className="logoContainer">
						<NavLink
							to={ {pathname: metadata.home.route}}
							title={metadata.home.title}
							activeClassName="active"
							onClick={()=>{
								setHeaderLinksState(null)
								setTimeout(()=>{
									setHeaderLinksState(headerLinks)
								}, 200)
							}}
						>
							<img id='logo' src={`/${config.HEADER_IMAGE}`} title={metadata.home.title} alt={metadata.home.title} />
							<img id='logoMobile' src={`/${config.HEADER_MOBILE_IMAGE}`} title={metadata.home.title} alt={metadata.home.title} />
						</NavLink>
					</div>
					<div className="linksContainer">
						{headerLinksState && (
							<LinkTiles links={headerLinksState} linksDirection='horizontalContainer' />
						)}
					</div>

				</div>
			</div>
		</Fragment>

	)

}

const mapStateToProps = (state, ownProps)  =>{
	const headerLinks = state.navigation ? state.navigation.header.links : []
	return {
	 	headerLinks
	 }
}

const mapDispatchToProps = {
	getAllFeatureArticles
}

export default  connect(mapStateToProps, mapDispatchToProps)(Header)
// export default Header
