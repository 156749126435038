import * as actionTypes from './action-types'
import axios from 'axios'
import { TIME_LIMIT_FOR_FETCH } from '../../utils/constants'
import {metadata} from "../../app-config"
import forOwn from 'lodash/forOwn'
import includes from 'lodash/includes'
import *  as APP_CONFIG from '../../config'

export const getFeatureServices = props => {
  return (dispatch, getState) =>

  {
    const timeSinceLastFetch = getState().featureArticles.featureServices.lastFetch
    let isDataStale = Date.now() - timeSinceLastFetch > TIME_LIMIT_FOR_FETCH //
    if(isDataStale){
      const maxItemLength= 8
      const pageNo = 1
      const categories = '3'
      const tags ='7'

      let url = `${APP_CONFIG.CONTENT_URL}?rest_route=/wp/v2/posts&categories=${categories}&tags=${tags}&page=${pageNo}&_embed&per_page=${maxItemLength}`
      if (window.DEBUG) console.time(url)
      dispatch({ type: actionTypes.GET_FEATURE_SERVICES_REQUEST })
      if (window.DEBUG) console.time(url)
      return axios
        .get(url)
        .then(response => {
          if (window.DEBUG) console.timeEnd(url)

          const responseData = response && response.data ? response.data : []
          let tempResponse = []
          for (const item of responseData) {
            tempResponse.push({
              icon: {
                url: item._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url,
                title: item.title.rendered
              },
              title: {
                text: item.title.rendered
              },
              text: () => {
                return (
                  item.excerpt.rendered
                )
              },
              linkURL:`${metadata.services.route.slice(1)}/${item.slug}`
            })
          }

          dispatch({
            type: actionTypes.GET_FEATURE_SERVICES_SUCCESS,
            payload: tempResponse
          })
        })
        .catch(error => {
          if (window.DEBUG) console.timeEnd(url)
          console.log(error)
          dispatch({
            type: actionTypes.GET_FEATURE_SERVICES_FAIL,
            error
          })
        })
    }

  }
}

export const getFeatureBlogs = props => {

  return (dispatch, getState) => {

    const timeSinceLastFetch = getState().featureArticles.featureBlogs.lastFetch
    let isDataStale = Date.now() - timeSinceLastFetch > TIME_LIMIT_FOR_FETCH //
    if(isDataStale) {
      const maxItemLength= 8
      const pageNo = 1
      const categories = '2'
      const tags ='6'

      let url = `${APP_CONFIG.CONTENT_URL}?rest_route=/wp/v2/posts&categories=${categories}&tags=${tags}&page=${pageNo}&_embed&per_page=${maxItemLength}`
      if (window.DEBUG) console.time(url)
      dispatch({ type: actionTypes.GET_FEATURE_BLOGS_REQUEST })
      if (window.DEBUG) console.time(url)
      return axios
        .get(url)
        .then(response => {
          if (window.DEBUG) console.timeEnd(url)

          const responseData = response && response.data ? response.data : []
          let tempResponse = []
          for (const item of responseData) {
            tempResponse.push({
              icon: {
                url: item._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url,
                title: item.title.rendered
              },
              title: {
                text: item.title.rendered
              },
              text: () => {
                return (
                  item.excerpt.rendered
                )
              },
              linkURL: `${metadata.blogs.route.slice(1)}/${item.slug}`
            })
          }
          // console.log(tempResponse)
          dispatch({
            type: actionTypes.GET_FEATURE_BLOGS_SUCCESS,
            payload: tempResponse
          })
        })
        .catch(error => {
          if (window.DEBUG) console.timeEnd(url)
          console.log(error)
          // need to do something here when get 403 error. this means the api end point is forbidden (not auth)
          dispatch({
            type: actionTypes.GET_FEATURE_BLOGS_FAIL,
            error
          })
        })
    }
  }
}

export const getFeaturePackages = props => {

  return (dispatch, getState) => {

    const timeSinceLastFetch = getState().featureArticles.featurePackages.lastFetch
    let isDataStale = Date.now() - timeSinceLastFetch > TIME_LIMIT_FOR_FETCH //
    if(isDataStale) {
      const maxItemLength= 8
      const pageNo = 1
      const categories = '21'
      const tags ='22'

      let url = `${APP_CONFIG.CONTENT_URL}?rest_route=/wp/v2/posts&categories=${categories}&tags=${tags}&page=${pageNo}&_embed&per_page=${maxItemLength}`
      if (window.DEBUG) console.time(url)
      dispatch({ type: actionTypes.GET_FEATURE_PACKAGES_REQUEST })
      if (window.DEBUG) console.time(url)
      return axios
        .get(url)
        .then(response => {
          if (window.DEBUG) console.timeEnd(url)

          const responseData = response && response.data ? response.data : []
          let tempResponse = []
          for (const item of responseData) {
            tempResponse.push({
              icon: {
                url: item._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url,
                title: item.title.rendered
              },
              title: {
                text: item.title.rendered
              },
              text: () => {
                return (
                  item.excerpt.rendered
                )
              },
              linkURL: `${metadata.packages.route.slice(1)}/${item.slug}`
            })
          }
          // console.log(tempResponse)
          dispatch({
            type: actionTypes.GET_FEATURE_PACKAGES_SUCCESS,
            payload: tempResponse
          })
        })
        .catch(error => {
          if (window.DEBUG) console.timeEnd(url)
          console.log(error)
          // need to do something here when get 403 error. this means the api end point is forbidden (not auth)
          dispatch({
            type: actionTypes.GET_FEATURE_PACKAGES_FAIL,
            error
          })
        })
    }
  }
}

export const getFeatureFaqs = props => {

  return (dispatch, getState) => {

    const timeSinceLastFetch = getState().featureArticles.featureFaqs.lastFetch
    let isDataStale = Date.now() - timeSinceLastFetch > TIME_LIMIT_FOR_FETCH //
    if(isDataStale) {
      const maxItemLength= 8
      const pageNo = 1
      const categories = '33'
      const tags ='34'

      let url = `${APP_CONFIG.CONTENT_URL}?rest_route=/wp/v2/posts&categories=${categories}&tags=${tags}&page=${pageNo}&_embed&per_page=${maxItemLength}`
      if (window.DEBUG) console.time(url)
      dispatch({ type: actionTypes.GET_FEATURE_FAQS_REQUEST })
      if (window.DEBUG) console.time(url)
      return axios
        .get(url)
        .then(response => {
          if (window.DEBUG) console.timeEnd(url)

          const responseData = response && response.data ? response.data : []
          let tempResponse = []
          for (const item of responseData) {
            tempResponse.push({
              icon: {
                url: item._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url,
                title: item.title.rendered
              },
              title: {
                text: item.title.rendered
              },
              text: () => {
                return (
                  item.excerpt.rendered
                )
              },
              linkURL: `${metadata.faqs.route.slice(1)}/${item.slug}`
            })
          }
          // console.log(tempResponse)
          dispatch({
            type: actionTypes.GET_FEATURE_FAQS_SUCCESS,
            payload: tempResponse
          })
        })
        .catch(error => {
          if (window.DEBUG) console.timeEnd(url)
          console.log(error)
          // need to do something here when get 403 error. this means the api end point is forbidden (not auth)
          dispatch({
            type: actionTypes.GET_FEATURE_FAQS_FAIL,
            error
          })
        })
    }
  }
}

export const getFeatureProducts = props => {

  return (dispatch, getState) => {

    const timeSinceLastFetch = getState().featureArticles.featureProducts.lastFetch
    let isDataStale = Date.now() - timeSinceLastFetch > TIME_LIMIT_FOR_FETCH //
    if(isDataStale) {
      const maxItemLength= 8
      const pageNo = 1
      const categories = '36'
      const tags ='38'

      let url = `${APP_CONFIG.CONTENT_URL}?rest_route=/wp/v2/posts&categories=${categories}&tags=${tags}&page=${pageNo}&_embed&per_page=${maxItemLength}`
      if (window.DEBUG) console.time(url)
      dispatch({ type: actionTypes.GET_FEATURE_PRODUCTS_REQUEST })
      if (window.DEBUG) console.time(url)
      return axios
        .get(url)
        .then(response => {
          if (window.DEBUG) console.timeEnd(url)

          const responseData = response && response.data ? response.data : []
          let tempResponse = []
          for (const item of responseData) {
            tempResponse.push({
              icon: {
                url: item._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url,
                title: item.title.rendered
              },
              title: {
                text: item.title.rendered
              },
              text: () => {
                return (
                  item.excerpt.rendered
                )
              },
              linkURL: `${metadata.products.route.slice(1)}/${item.slug}`
            })
          }
          // console.log(tempResponse)
          dispatch({
            type: actionTypes.GET_FEATURE_PRODUCTS_SUCCESS,
            payload: tempResponse
          })
        })
        .catch(error => {
          if (window.DEBUG) console.timeEnd(url)
          console.log(error)
          // need to do something here when get 403 error. this means the api end point is forbidden (not auth)
          dispatch({
            type: actionTypes.GET_FEATURE_PRODUCTS_FAIL,
            error
          })
        })
    }
  }
}

export const getFeatureTechnicalDoc = props => {

  return (dispatch, getState) => {

    const timeSinceLastFetch = getState().featureArticles.featureTechnicalDocuments.lastFetch
    let isDataStale = Date.now() - timeSinceLastFetch > TIME_LIMIT_FOR_FETCH //
    if(isDataStale) {
      const maxItemLength= 8
      const pageNo = 1
      const categories = '40'
      const tags ='43'

      let url = `${APP_CONFIG.CONTENT_URL}?rest_route=/wp/v2/posts&categories=${categories}&tags=${tags}&page=${pageNo}&_embed&per_page=${maxItemLength}`
      if (window.DEBUG) console.time(url)
      dispatch({ type: actionTypes.GET_FEATURE_TECH_DOC_REQUEST })
      if (window.DEBUG) console.time(url)
      return axios
        .get(url)
        .then(response => {
          if (window.DEBUG) console.timeEnd(url)

          const responseData = response && response.data ? response.data : []
          let tempResponse = []
          for (const item of responseData) {
            tempResponse.push({
              icon: {
                url: item._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url,
                title: item.title.rendered
              },
              title: {
                text: item.title.rendered
              },
              text: () => {
                return (
                  item.excerpt.rendered
                )
              },
              linkURL: `${metadata.techDocs.route.slice(1)}/${item.slug}`
            })
          }
          // console.log(tempResponse)
          dispatch({
            type: actionTypes.GET_FEATURE_TECH_DOC_SUCCESS,
            payload: tempResponse
          })
        })
        .catch(error => {
          if (window.DEBUG) console.timeEnd(url)
          console.log(error)
          // need to do something here when get 403 error. this means the api end point is forbidden (not auth)
          dispatch({
            type: actionTypes.GET_FEATURE_TECH_DOC_FAIL,
            error
          })
        })
    }
  }
}

export const getAllFeatureArticles = props => {

  return (dispatch, getState) => {

    const timeSinceLastFetch = getState().featureArticles.allArticles.lastFetch
    let isDataStale = Date.now() - timeSinceLastFetch > TIME_LIMIT_FOR_FETCH //
    if(isDataStale) {
      //allow up to 20 feature items in total
      const maxItemLength= 20
      const pageNo = 1

      const featureContent = {
          SERVICES:{
            categories:'3',
            tags:'5',
            data:[],
            route:metadata.services.route
          },
          BLOGS:{
            categories:'2',
            tags:'6',
            data:[],
            route:metadata.blogs.route
          },
          FAQS:{
            categories:'33',
            tags:'34',
            data:[],
            route:metadata.faqs.route
          },
          PRODUCTS:{
            categories:'36',
            tags:'38',
            data:[],
            route:metadata.products.route
          },
          PACKAGES:{
            categories:'21',
            tags:'22',
            data:[],
            route:metadata.packages.route
          },
          TECH_DOC:{
            categories:'48',
            tags:'43',
            data:[],
            route:metadata.techDocs.route
          }
        }

        let allCategoriesArray = []
        let allTagsArray = []
        forOwn(featureContent, (featureTypeData, featureName) => {
          allCategoriesArray.push(featureTypeData['categories'])
          allTagsArray.push(featureTypeData['tags'])
        });
      const allCategories = allCategoriesArray.join()
      const allTags = allTagsArray.join()

      let url = `${APP_CONFIG.CONTENT_URL}?rest_route=/wp/v2/posts&categories=${allCategories}&tags=${allTags}&page=${pageNo}&_embed&per_page=${maxItemLength}`
      if (window.DEBUG) console.time(url)
      dispatch({ type: actionTypes.GET_FEATURE_ALL_REQUEST })
      if (window.DEBUG) console.time(url)
      return axios
        .get(url)
        .then(response => {
          if (window.DEBUG) console.timeEnd(url)
          //now call actions with data
          dispatch({
            type: actionTypes[`GET_FEATURE_ALL_SUCCESS`]
          })

          const responseData = response && response.data ? response.data : []
          // console.log(responseData)

          //go through each result
          for (const item of responseData) {
            //go through each featureContent
            forOwn(featureContent, (featureTypeData, featureName) => {
              //match the category and tags and place into relevant array
              if(
                includes(item.categories, parseInt(featureTypeData['categories'])) &&
                includes(item.tags, parseInt(featureTypeData['tags']))
              ){
                featureContent[featureName]['data'].push(item)
              }
            })
          }
          // console.log(featureContent)
          //go through each featureContent again to call relevant actions (if required)
          forOwn(featureContent, (featureTypeData, featureName) => {
            //if we have data
            //console.log(featureContent[featureName].data)
            if(featureContent[featureName].data.length > 0){
              let tempResponse = []
              //go through the data array
              for (const featureArticle of featureContent[featureName].data) {
                tempResponse.push({
                  icon: {
                    url: featureArticle._embedded['wp:featuredmedia']
                      ? featureArticle._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url : '',
                    title: featureArticle.title.rendered
                  },
                  title: {
                    text: featureArticle.title.rendered
                  },
                  text: () => {
                    return (
                      featureArticle.excerpt.rendered
                    )
                  },
                  linkURL: `${featureContent[featureName]['route'].slice(1)}/${featureArticle.slug}`
                })
              }
              //now call actions with data
              dispatch({
                type: actionTypes[`GET_FEATURE_${featureName}_SUCCESS`],
                payload: tempResponse
              })
            }
          })
        })
        .catch(error => {
          if (window.DEBUG) console.timeEnd(url)
          console.log(error)
          // need to do something here when get 403 error. this means the api end point is forbidden (not auth)
          dispatch({
            type: actionTypes.GET_FEATURE_ALL_FAIL,
            error
          })
        })
    }
  }
}


export const featureArticleReset = props => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.FEATURE_ARTICLES_RESET,
    })
  }
}
