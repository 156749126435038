import React from 'react'
import Loading from './components/common/loading'
import Loadable from 'react-loadable'
import Animate from 'animate.css-react'

const AnimatedWrapper = props =>{
    const {loaded, componentProps} = props
    let Component = loaded

    return(
        <Animate
            enter="fadeIn" // on Enter animation
            leave="fadeOut" // on Leave animation
            appear="fadeIn" // on element Appear animation (onMount)
        >
            <Component {...componentProps}/>
        </Animate>);
}

const Landing = Loadable({
    loader: () => import('./pages/home'),
    render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
    loading: () => (
            <div className="d-flex section-layout-1200">
                <Loading
                    message="Loading...."
                />
            </div>
        )
})

const Services = Loadable({
    loader: () => import('./pages/services'),
    render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                message="Loading Services...."
            />
        </div>
    )
})

const GalleryPage = Loadable({
    loader: () => import('./pages/gallery'),
    render(loaded, props) {
        let Component = loaded.default;
        return(
            <Animate
                enter="fadeIn" // on Enter animation
                leave="fadeOut" // on Leave animation
                appear="fadeIn" // on element Appear animation (onMount)
            >
                <Component {...props}/>
            </Animate>);
    },
    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                message="Loading Gallery ...."
            />
        </div>
    )
})


const Contact = Loadable({
    loader: () => import('./pages/contact'),
    render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                message="Loading Contact......"
            />
        </div>
    )
})

const About = Loadable({
  loader: () => import('./pages/about'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading About Us ......"
      />
    </div>
  )
})



const TechnicalDocsPage = Loadable({
  loader: () => import('./pages/technical-docs'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading ......"
      />
    </div>
  )
})
const TechnicalDocPage = Loadable({
  loader: () => import('./pages/technical-docs/technical-doc'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Article ......"
      />
    </div>
  )
})
const ServicePage = Loadable({
  loader: () => import('./pages/services/service'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Page ......"
      />
    </div>
  )
})
const FaqsPage = Loadable({
  loader: () => import('./pages/faqs'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading FAQs ......"
      />
    </div>
  )
})
const FaqPage = Loadable({
  loader: () => import('./pages/faqs/faq'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading FAQ ......"
      />
    </div>
  )
})
const BlogsPage = Loadable({
  loader: () => import('./pages/blogs'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Blogs ......"
      />
    </div>
  )
})
const BlogPage = Loadable({
  loader: () => import('./pages/blogs/blog'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Blog ......"
      />
    </div>
  )
})

const ProductsPage = Loadable({
  loader: () => import('./pages/products'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Products ......"
      />
    </div>
  )
})
const ProductPage = Loadable({
  loader: () => import('./pages/products/product'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Product Information ......"
      />
    </div>
  )
})

const TestimonialsPage = Loadable({
  loader: () => import('./pages/testimonials'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Testimonials  ......"
      />
    </div>
  )
})

const SearchPage = Loadable({
  loader: () => import('./pages/search'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Search Page ......"
      />
    </div>
  )
})

const PackagesPage = Loadable({
  loader: () => import('./pages/packages'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Package Content ......"
      />
    </div>
  )
})

const PackagePage = Loadable({
  loader: () => import('./pages/packages/package'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Package Content ......"
      />
    </div>
  )
})

const GlossaryPage = Loadable({
  loader: () => import('./pages/glossary'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Glossary ......"
      />
    </div>
  )
})

const PortfolioPage = Loadable({
  loader: () => import('./pages/portfolio'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Portfolio ......"
      />
    </div>
  )
})

const SpecialOffersPage = Loadable({
  loader: () => import('./pages/special-offers'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Special Offers ......"
      />
    </div>
  )
})

const SpecialOfferPage = Loadable({
  loader: () => import('./pages/special-offers/special-offer'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Special Offer ......"
      />
    </div>
  )
})

const FormsPage = Loadable({
  loader: () => import('./pages/forms'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Forms ......"
      />
    </div>
  )
})
const FormPage = Loadable({
  loader: () => import('./pages/forms/form'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Form ......"
      />
    </div>
  )
})

const ToolsPage = Loadable({
  loader: () => import('./pages/tools'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Tools ......"
      />
    </div>
  )
})
const ToolPage = Loadable({
  loader: () => import('./pages/tools/tool'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Tool ......"
      />
    </div>
  )
})

export {
  Landing,
  Services,
  Contact,
  About,
  GlossaryPage,
  SearchPage,
  GalleryPage,
  TestimonialsPage,
  BlogsPage,
  BlogPage,
  FaqsPage,
  FaqPage,
  ProductsPage,
  ProductPage,
  PackagesPage,
  PackagePage,
  ServicePage,
  TechnicalDocsPage,
  TechnicalDocPage,
  PortfolioPage,
  SpecialOffersPage,
  SpecialOfferPage,
  FormsPage,
  FormPage,
  ToolsPage,
  ToolPage
}
