import React, { useEffect } from 'react'
import { gsap } from "gsap"
import anime from 'animejs'
require ('./big-logo-loader.scss')

const BigLogoLoader = props => {
    // constructor(props) {
    //     super(props)
    //     this.state = {}
    //     this.tl = new gsap.timeline({ repeat: -1 })
    //     this.tl2 = new gsap.timeline({ repeat: -1 })
    // }

    useEffect(()=>{

      const tl = new gsap.timeline({ repeat: -1 })
      const tl2 = new gsap.timeline({ repeat: -1 })

      const selector1 = '.large-select-1'
      const selector2 = '.large-select-0, .large-select-2'

    //pulsate animation
    //   tl.set(selector1, { autoAlpha: 0.7 })
    //     .to(selector1, 0.5, {
    //       scale: 0.8,
    //       transformOrigin: '50% 50%'
    //     })
    //     .to(selector1, 0.5, { scale: 1,  transformOrigin: '50% 50%' })

      //page turn
      // tl.from(selector1, 1, { scaleX: 0, transformOrigin: "left" })
      // tl.to(selector1, 1, { scaleX: 0, transformOrigin: "right" }, "reveal")
      // tl.from(selector1, 1, { opacity: 0 }, "reveal")

        tl.to(selector1, 1, {drawSVG:true})

      const svgText = anime.timeline({

        endDelay: 0,
        easing: 'easeInOutQuad',
        direction: 'alternate',
        loop: true
      })

        .add({ targets: ['.large-select-1',],
          //points: '80.1,0 43.7,48.2 34.3,48.2 31.9,24.9 0,62.7 30.8,17.3 39.3,17.3 42.3,42.3 ',
          baseFrequency: 3,

        })
        .add({ targets: '.large-select-0',  fill: '#ffb793' }, 0)

    },[])



    //render() {
        return (
          <div className="small-loading-icon-container" style={{ width: '100px', height: '130px', top: '20px' }}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="90.4px"
                 height="90.4px" viewBox="0 0 90.4 90.4"
            >
              <polygon className="st0 path" fill="#ED6100"
                       points="80.1,0 43.7,48.2 34.3,48.2 31.9,24.9 0,62.7 30.8,17.3 39.3,17.3 42.3,42.3 "/>
              <g>
                <path className="large-select-0" d="M9.2,48.9C7.8,45.2,7,41,7,36.7C7,18.1,22.1,3,40.8,3C50.6,3,59.2,7,65.5,13.7l2.6-1.7C61.3,4.6,51.7,0,40.8,0
                    C20.5,0,3.9,16.6,3.9,36.9c0,4.8,0.9,9.4,2.6,13.7L9.2,48.9z"/>
                <path className="large-select-0" d="M73.6,20.1l-2.4,2c2.2,4.4,3.3,9.4,3.3,14.6c0,18.6-15.1,33.8-33.8,33.8c-10.7,0-20.3-5-26.6-12.9l-2.6,1.5
                    c6.8,8.7,17.3,14.4,29.2,14.4c20.3,0,36.9-16.6,36.9-36.9C77.7,30.8,76.2,25.1,73.6,20.1z"/>
              </g>
            </svg>
          </div>
        )
    //}
}

export default BigLogoLoader
