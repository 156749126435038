import * as ROUTE_META from './data.json'
import *  as APP_CONFIG from '../config'
const metadata = ROUTE_META.metadata

export const headerLinks = [
    {
        internalLink:true,
        linkDetails:{
            label:metadata.home.linkName,
            url:metadata.home.route,
        }
    },
    {
        internalLink:true,
        linkDetails:{
            label:metadata.services.linkName,
            url:metadata.services.route,
        }
    },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.products.linkName,
    //         url:metadata.products.route,
    //     }
    // },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.packages.linkName,
    //         url:metadata.packages.route,
    //     }
    // },

    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.tools.linkName,
    //         url:metadata.tools.route,
    //     }
    // },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.forms.linkName,
    //         url:metadata.forms.route,
    //     }
    // },
    {
        internalLink:true,
        linkDetails:{
            label:metadata.specialOffers.linkName,
            url:metadata.specialOffers.route,
        }
    },


    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.testimonials.linkName,
    //         url:metadata.testimonials.route,
    //     }
    // },
    //{
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.faqs.linkName,
    //         url:metadata.faqs.route,
    //     }
    // },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.techDocs.linkName,
    //         url:metadata.techDocs.route,
    //     }
    // },
    {
        internalLink:true,
        linkDetails:{
            label:metadata.blogs.linkName,
            url:metadata.blogs.route,
        }
    },
    {
        internalLink:true,
        linkDetails:{
            label:metadata.contact.linkName,
            url:metadata.contact.route,
        }
    },
    {
        internalLink:true,
        linkDetails:{
            label:metadata.about.linkName,
            url:metadata.about.route,
        }
    }


    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.glossary.linkName,
    //         url:metadata.glossary.route,
    //     }
    // },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.portfolio.linkName,
    //         url:metadata.portfolio.route,
    //     }
    // }
]




const renderInternalNavLinks = () =>{

    //render each internal link that is in the headerLinks array
    let footerNavLinks =[]
    for (const navLink of headerLinks) {
        if(navLink.internalLink === true) footerNavLinks.push({value: {...navLink}, type:'link'})
    }
    // console.log(footerNavLinks)
    return footerNavLinks
}


export const footerContent = {
    columns:[
        {
            content:[
                {
                    type:'title',
                    value:APP_CONFIG.WEBSITE_NAME
                },
                ...renderInternalNavLinks()
            ]
        },
        {
            content:[
                {
                    type:'title',
                    value:'Electrical Services'
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Split Systems',
                            url:`${metadata.services.route}/split-systems`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Residential Electrical Services',
                            url:`${metadata.services.route}/residential-electrical-services`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Corporate Documentation',
                            url:`${metadata.services.route}/corporate-documents-reports`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Commercial Air Conditioning',
                            url:`/${metadata.services.route}/commercial-air-conditioning-services`
                        }
                    }
                }
            ]
        },
        {
            content:[
                {
                    type:'title',
                    value:'Special Offers'
                },
                ,
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Split System Supply and Install',
                            url:`${metadata.specialOffers.route}/split-system-supply-and-install`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Switch Board Inspections',
                            url:`${metadata.specialOffers.route}/free-switch-board-inspection`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Smoke Detector Testing',
                            url:`${metadata.specialOffers.route}/free-smoke-detector-testing`
                        }
                    }
                }
            ]
        },
        {
            content:[
                {
                    type:'title',
                    value:'Electrical Blogs'
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Split Systems in ACT',
                            url:`${metadata.blogs.route}/split-system-installation-in-act`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Bathurst Electrical Services',
                            url:`${metadata.blogs.route}/bathurst-electrical-services`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Hot Water Installations',
                            url:`${metadata.blogs.route}/hot-water-installations`
                        }
                    }
                },

                {
                    type:'img',
                    specialClass:'footerLogo',
                    value:{
                        src: `/${APP_CONFIG.FOOTER_IMAGE}`,
                        title: APP_CONFIG.WEBSITE_NAME,
                        alt: APP_CONFIG.WEBSITE_NAME
                    }
                }
            ]
        }
    ]
}
